/* eslint-disable array-callback-return */
import { useFormik } from "formik";
import React, { useContext, useRef, useState} from "react";
import ClipLoader from "react-spinners/ClipLoader";
import {useNavigate } from "react-router-dom";
import SignaturePad from "react-signature-canvas";
import * as Yup from "yup";
import Layout from "../../layout/Layout";
import { toast } from "react-toastify";
import { axiosInstance } from "../../config/AxiosInstance";
import { MainContext } from "../../context/MainContext";

const Covid2 = () => {
  const [loader, setLoader] = useState(false);

  const {data}= useContext(MainContext)
  console.log("the data is",data)
  const sigPad = useRef(null);
  const navigate = useNavigate("");
  let covid1 = data.covidForm
// loader css  customization start
const loadercss = {
  display: "block",
  margin: "0 auto",
  borderColor: "white",
};
// loader css  customization end
  const validationSchema = Yup.object({
    date: Yup.date().required("Date is Required"),
    participant_signature: Yup.string().required(
      "Participant signature is required"
    ),
  });

  const isdelete = () => {
    if (formik.values.participant_signature) {
      formik.setFieldValue("participant_signature", "");
    }
  };

  const trim = () => {
    const trimmedDataURL = sigPad.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    formik.setFieldValue("participant_signature", trimmedDataURL);
  };
  const symbols=['x','p'];
  const formik = useFormik({
    initialValues: {
      date: "",
      participant_signature: null,

    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoader(true);
      const userData=data.user;
      console.log(userData)
      const dobDate = new Date(userData.dob);
      const currentYear= new Date().getFullYear()
      const age =currentYear - dobDate.getFullYear();
     
      const formdata = new FormData();
      //userDataFrom

      formdata.append("symbol", symbols[Math.floor(Math.random() * 2)]);
      formdata.append("name", userData.name);
      formdata.append("email", userData.email);
      formdata.append("address", userData.address);
      formdata.append("race", userData.race);
      formdata.append("height", userData.height);
      formdata.append("weight", userData.weight);
      formdata.append("dob", userData.dob);
      formdata.append("medical_record", userData.medical_record);
      formdata.append("driver_license", userData.driver_license);
      formdata.append("vaccine", userData.vaccine);
      formdata.append("vaccine_name", userData.vaccine_name);
      formdata.append("month", userData.vaccine_month);
      formdata.append("year", userData.vaccine_year);

      //Covid Form 1
      if(covid1 != null){
      formdata.append("age", age);
      covid1.viral_symptoms.map((symptom, i) => {
        console.log("sysmbt ",symptom)
        formdata.append(`viral_symptoms[${i}]`, symptom);
      });
      formdata.append(`covid_test_date`, data.testDate);
      formdata.append("legal_guardian", covid1.legal_guardian);
    }
    else{
      formdata.append("age",age);
      
        formdata.append(`viral_symptoms[0]`, "None");
   
      formdata.append(`covid_test_date`, "None");
      formdata.append("legal_guardian", "None");
    }
      //Covid Form 2
      formdata.append("date", values.date);
      formdata.append("participant_signature", values.participant_signature);

      axiosInstance
        .post("participate/store", formdata)
        .then((response) => {
          if (response.data.status) {
            toast.success(response.data.message);
            navigate("/");
          } else {
            toast.error(response.data.message);
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });

    },
  });
  return (
    <Layout>
      <section className="banner-section home-3">
        <div className="container">
          <div className="row">
            <div className=" col-md-12">
              <div className="covid-custom-card card bg-transparent rounded shadow-lg">
                <div className="container p-3  position-relative">
                  <form onSubmit={formik.handleSubmit}>
                    <h2 className="text-center  ">
                      Informed Consent Form
                    </h2>
                    <div className="my-2">
                      <p className=" ">
                        Study Title: Evaluation of Herbal Medicine for the
                        Treatment of COVID-19
                      </p>
                    </div>{" "}
                    <div className="my-2">
                      <p className=" ">
                        Introduction: You are being invited to participate in a
                        clinical trial evaluating the effectiveness and safety
                        of a herbal medicine for the treatment of COVID-19.
                        Before you decide whether to participate, it is
                        important that you understand the purpose, procedures,
                        potential risks, and benefits of the trial. Please read
                        this form carefully and ask any questions you may have
                        before making your decision.
                      </p>
                    </div>{" "}
                    <div className="my-2">
                      <p className=" ">
                        Purpose of the Study: The purpose of this study is to
                        assess whether the herbal medicine, HerbGreenX, is
                        effective in treating COVID-19 symptoms and reducing the
                        duration of illness. The trial aims to contribute to the
                        development of alternative treatment options for
                        COVID-19.
                      </p>
                    </div>{" "}
                    <div className="my-2">
                      <p className=" ">
                        Study Procedures:
                        <ul>
                          <li>
                            Screening: You will undergo screening to determine
                            your eligibility for the trial. This may involve
                            medical history review, physical examination,
                            laboratory tests, and COVID-19 diagnostic tests.
                          </li>
                          <li>
                            Treatment: If you meet the eligibility criteria and
                            choose to participate, you will be randomly assigned
                            to receive either the herbal medicine or a
                            placebo/control treatment.
                          </li>
                          <li>
                            Follow-up Visits: You will be required to fill out
                            the Participant Diary for six days through a mobile
                            app or website. Your symptoms, vital signs, and any
                            adverse events will be assessed.
                          </li>
                          <li>
                            Data Collection: Information regarding your medical
                            history, COVID-19 symptoms, and treatment response
                            will be collected and recorded. Your privacy and
                            confidentiality will be maintained.
                          </li>
                        </ul>
                      </p>
                    </div>{" "}
                    <div className="my-2">
                      <p className=" ">
                        Risks and Benefits:
                        <ul>
                          <li>
                            Potential Benefits: You may experience improvement
                            in COVID-19 symptoms, a reduction in illness
                            duration, and contribute to the development of
                            alternative treatment options for COVID-19.
                          </li>
                          <li>
                            Potential Risks: There is a possibility of
                            experiencing side effects or adverse reactions to
                            the herbal medicine or placebo/control treatment. If
                            you start to experience severe symptoms or have
                            difficulty breathing, seek medical attention
                            immediately.
                          </li>
                        </ul>
                      </p>
                    </div>{" "}
                    <div className="my-2">
                      <p className=" ">
                        Confidentiality and Data Handling: Your identity and
                        personal information will be kept confidential to the
                        extent allowed by law. Data collected during the trial
                        will be coded and stored securely. Only authorized
                        personnel involved in the trial will have access to this
                        information. However, in the interest of public safety,
                        certain data may be shared with regulatory authorities
                        and research collaborators.
                      </p>
                    </div>{" "}
                    <div className="my-2">
                      <p className=" ">
                        Voluntary Participation and Withdrawal: Participation in
                        this trial is entirely voluntary. You have the right to
                        refuse participation or withdraw from the trial at any
                        time without penalty or loss of benefits. Your decision
                        will not affect your future medical care.
                      </p>
                    </div>{" "}
                    {/* <div className="my-2">
                      <p className=" ">
                        Contact Information: For any questions, concerns, or
                        emergencies related to the trial, please contact the
                        following individuals:
                        <ul>
                          <li>
                            Principal Investigator:{" "}
                            <input
                              name="principal_investigator"
                              type="text"
                              className="w-25    bg-transparent"
                              style={{
                                borderBottom: "1px solid ",
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                              }}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.principal_investigator}
                            />
                            {formik.touched.principal_investigator &&
                            formik.errors.principal_investigator ? (
                              <div className="text-danger">
                                {formik.errors.principal_investigator}
                              </div>
                            ) : null}
                          </li>
                          <li>
                            Study Coordinator:{" "}
                            <input
                              name="study_coordinator"
                              type="text"
                              className="w-25    bg-transparent"
                              style={{
                                borderBottom: "1px solid ",
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                              }}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.study_coordinator}
                            />
                            {formik.touched.study_coordinator &&
                            formik.errors.study_coordinator ? (
                              <div className="text-danger">
                                {formik.errors.study_coordinator}
                              </div>
                            ) : null}
                          </li>
                          <li>
                            Institutional Review Board:{" "}
                            <input
                              name="institutional_review_board"
                              type="text"
                              className="w-25    bg-transparent"
                              style={{
                                borderBottom: "1px solid ",
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                              }}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.institutional_review_board}
                            />
                            {formik.touched.institutional_review_board &&
                            formik.errors.institutional_review_board ? (
                              <div className="text-danger">
                                {formik.errors.institutional_review_board}
                              </div>
                            ) : null}
                          </li>
                        </ul>
                      </p>
                    </div>{" "} */}
                    <div className="my-2">
                      <p className=" ">
                        By signing below, you confirm that you have read and
                        understood the information provided in this consent
                        form. You have had the opportunity to ask questions and
                        have received satisfactory answers.
                      </p>
                    </div>{" "}
                    <div className="container">
                      <div className="row">
                        <div className="offset-md-3 col-md-4 position-relative">
                          <h6 className=" ">
                            Participant Signature:{" "}
                          </h6>
                          <div className="  border bg-white ">
                            {formik.values.participant_signature ? (
                              <img
                                className={""}
                                src={formik.values.participant_signature}
                                alt="Signature"
                              />
                            ) : (
                              <>
                                <SignaturePad
                                  onEnd={trim}
                                  canvasProps={{ className: "sigPad" }}
                                  ref={sigPad}
                                />
                              </>
                            )}
                          </div>
                          {formik.touched.participant_signature &&
                            formik.errors.participant_signature ? (
                            <div className="text-danger">
                              {formik.errors.participant_signature}
                            </div>
                          ) : null}
                          {formik.values.participant_signature && (
                            <button
                              className={
                                "btn  mt-1 position-absolute top-0 end-0 "
                              }
                              onClick={isdelete}
                            >
                              <i className="icofont-trash text-danger" />
                            </button>
                          )}
                        </div>
                        <div className="col-md-4 pt-auto ">
                          <h6 className=" ">Date: </h6>
                          <input
                            type="date"
                            name="date"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.date}
                          />
                          {formik.touched.date && formik.errors.date ? (
                            <div className="text-danger">
                              {formik.errors.date}
                            </div>
                          ) : null}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="float-end">
                      <button
                        className="btn btn-warning   "
                        type="submit"
                        disabled={loader ? true : false}
                      >
                        {loader ?
                       <ClipLoader
                       color={"white"}
                       loading={loader}
                       cssOverride={loadercss}
                       size={30}
                       aria-label="Loading Spinner"
                       data-testid="loader"
                     /> 
                     :
                     <span>   Submit <i className="icofont-arrow-right text-primary" /></span>
                      }
                     
                       
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Covid2;
