
import React, { useContext, useState } from "react";
import {  useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { MainContext } from "../../context/MainContext";
import Layout from "../../layout/Layout"
import { useFormik } from "formik";

const CovidPositive = () => {
    const [asPositive,setAsPositive]= useState(null)
    const {data,setData} = useContext(MainContext)
    const navigate = useNavigate();
    const validationSchema = Yup.object({
      
        covid_test_date: Yup.date().required("Select covid test date"),
    });
    const formik = useFormik({
        initialValues: {
          
            covid_test_date:"",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setData({
                ...data,
                testDate:values.covid_test_date
            })
            navigate('/exlusion-criteria')
        
        },
       
    })
    return (
        <>
            <Layout>
                <section className="banner-section home-3" style={{ padding: '200px 0px 100px 0' }}>
                    <div className="">
                        <div className="container">
                            <div className="row">
                                <div className="m-auto col-md-10">
                                    <div className="card bg-transparent rounded shadow-lg">
                                        <div className="container p-3">
                                            <div className="row text-center p-5">
                                                <div className="col-md-12 ">
                                                        <h5 className="text-black ">Have you tested positive or negative for COVID? If positive, please provide the date of your COVID test</h5>
                                                </div>
                                               {asPositive ?
                                                <div className="col-5 m-auto">
                                                <form id="testDate"  onSubmit={formik.handleSubmit}>
                                                        
                                                        <div className=" form-group">
                                                    <h6 className="text-left ">
                                covid test date
                              </h6>
                              <input
                              type="date"
                                className="form-control"
                                name="covid_test_date"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.covid_test_date}
                              />
                              {formik.touched.covid_test_date &&
                                formik.errors.covid_test_date && (
                                  <div className="text-danger">
                                    {formik.errors.covid_test_date}
                                  </div>
                                )}
                                                    </div>
                                                    <button 
                                                    form="testDate"
                                                    type="submit"
                                                  
                                                    className="btn btn-lg  btn-warning text-white">Continue</button>
                                                        </form> 
                                                </div>
                                                    :
                                                <div className="col-md-12 d-flex gap-3 justify-content-center  align-items-center">
                                                 
                                                    <button 
                                                   
                                                    type="button"
                                                  onClick={()=>setAsPositive(!asPositive)}
                                                    className="btn btn-lg  btn-warning text-white">Positive</button>
                                                    <button 
                                                    onClick={()=>navigate('/covid')}
                                                    className="btn btn-lg btn-success text-white ">Negative</button>
                                                </div> }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default CovidPositive