import { useFormik } from "formik";
import React, { useContext } from "react";
import {   useNavigate } from "react-router-dom";
// import Select from "react-select";
import * as Yup from "yup";
import Layout from "../../layout/Layout";
import { MainContext } from "../../context/MainContext";

const Covid = () => {
  const navigate = useNavigate("");
  // const location = useLocation();
  const {data,setData}=useContext(MainContext)

  const validationSchema = Yup.object({
    viral_symptoms: Yup.array().min(2, "Select at least two viral symptom"),
    // covid_test_date: Yup.date().required("Select covid test date"),
    legal_guardian: Yup.boolean()
    .oneOf([true], "Must be checked for further operations")
    .required("Must be checked for further operations."),
  });
  const formik = useFormik({
    initialValues: {
      legal_guardian: false,
      viral_symptoms: [],
      covid_test_date: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setData({
        ...data,
        covidForm:values
      })
      // localStorage.setItem("covidform1", JSON.stringify(values));
    
      if(values.viral_symptoms.length>=2){

        navigate("/exlusion-criteria");
      }
      else{
        navigate('/not-eligible')
      }
    },
  });
  const options = [
    { value: "fever", label: "Fever" },
    { value: "sore_throat", label: "Sore Throat" },
    { value: "cough", label: "Cough" },
    { value: "pink_eye", label: "Pink Eye" },
    { value: "fatigue", label: "Fatigue" },
    { value: "loss_of_taste_or_smell", label: "Loss of taste or smell" },
  ];

  return (
    <Layout>
      <section className="banner-section home-3">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="offset-md-3 col-md-6">
                <div className="card bg-transparent rounded shadow-lg">
                  <div className="container p-3">
                  
                  <form onSubmit={formik.handleSubmit}>
                          <h6 className="mb-3  ">
                          What symptoms, likely caused by a virus, do you have?

                          </h6>
                          {/* <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            isMulti
                            name="viral_symptoms"
                            options={options}
                            value={formik.values.viral_symptoms}
                            onChange={(selectedOption) =>
                              formik.setFieldValue(
                                "viral_symptoms",
                                selectedOption
                              )
                            }
                            onBlur={formik.handleBlur}
                          /> */}
                                {options.map((data,index)=>(
                                                    <div className="form-check">
                                                    <input
                                                        className="form-check-input px-2 py-2"
                                                        name="viral_symptoms"
                                                        type="checkbox"
                                                      
                                                        id={"flexCheckIndeterminate"+index}
                                                        value={data.value}
                                                        onChange={formik.handleChange}
                                                       
                                                        
                                                        
                                                    />
                                                    <label className="form-check-label     fs-6" htmlFor={"flexCheckIndeterminate"+index}>
                                                    {data.label}
                                                    </label>
                                                    </div>
                                                ))}
                                           
                          {formik.touched.viral_symptoms &&
                            formik.errors.viral_symptoms && (
                              <div className="text-danger">
                                {formik.errors.viral_symptoms}
                              </div>
                            )}
                   {/* date  input start */}
                   {/* date  input end */}
                          <div className="row mt-4">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                              <input
                                className="form-check"
                                type="checkbox"
                                width={22}
                                name="legal_guardian"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.legal_guardian}
                              />
                            </div>
                            <div className="col-lg-11 col-md-11 col-sm-11 col-11">
                              <h6 className=" ">
                                Be able to provide informed consent. In the case
                                of a minor participant, a legal guardian must
                                provide informed consent.
                              </h6>
                            </div>
                            {formik.touched.legal_guardian &&
                              formik.errors.legal_guardian ? (
                              <div className="text-danger">
                                {formik.errors.legal_guardian}
                              </div>
                            ) : null}
                          </div>
                          <div className="float-end">
                            <button
                              className="btn btn-warning   "
                              type="submit"
                            >
                              Next <i className="icofont-arrow-right text-primary" />
                            </button>
                          </div>
                        </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Covid;
