import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { axiosInstance } from "../config/AxiosInstance";
import { toast } from "react-toastify";
import Layout from "../layout/Layout";

const Login = () => {
  const [loader, setLoaders] = useState(false);
  const navigate = useNavigate("");
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoaders(true);
      const formdata = new FormData();
      formdata.append("email", values.email);
      formdata.append("password", values.password);
      axiosInstance
        .post("login", formdata)
        .then((response) => {
          console.log(response, "response");
          if (response.data.status === true) {
            setLoaders(false);
            localStorage.setItem(
              "UserDetails",
              JSON.stringify(response.data.data)
            );
            localStorage.setItem("Token", response.data.token);
            toast.success(response.data.message);
            if (response.data.data.status === "false") {
              navigate("/covid");
            } else {
              navigate("/");
            }
          } else {
            setLoaders(false);
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          setLoaders(false);
          console.log(err);
        });
    },
  });

  return (
    <>
      {loader && <div className="loader" />}
      <Layout>
        <section className="banner-section home-3" style={{ height: "100vh" }}>
          <div className="container">
            <div className="row">
              <div className="offset-md-3 col-md-6">
                <div className="card shadow-lg bg-transparent">
                  <div className="container">
                    <div className="text-center ">
                      <h3 className="text-white pt-3">Login</h3>
                    </div>
                    <form onSubmit={formik.handleSubmit} className="my-3">
                      <label className="text-white">Email</label>
                      <input
                        name="email"
                        placeholder="Email"
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-danger">{formik.errors.email}</div>
                      ) : null}
                      <label className="text-white">Password</label>
                      <input
                        name="password"
                        placeholder="Password"
                        type="password"
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <div className="text-danger">
                          {formik.errors.password}
                        </div>
                      ) : null}

                      <div className="text-center my-3">
                        <button
                          type="submit"
                          className="btn btn-warning text-white w-50"
                          disabled={loader}
                        >
                          {" Login"}
                        </button>
                        <div className="mt-2 text-light">
                          Don't Have An Account?{" "}
                          <Link className="text-white" to={"/register"}>
                            Register Here
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Login;
