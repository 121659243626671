import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import {useNavigate } from "react-router-dom";

import * as Yup from "yup";
import Layout from "../../layout/Layout"
import { MainContext } from "../../context/MainContext";

const UserData = () => {
    const {data,setData} = useContext(MainContext)
    const [medical, setMedical] = useState()
    const [license, setLicense] = useState()
  

    const navigate = useNavigate("");
    const today = new Date();
    const maxDate = new Date(today.getFullYear() - 5, today.getMonth(), today.getDate());

    const validationSchema = Yup.object({
        
        name: Yup.string().required("Full Name is required"),
        email: Yup.string().email().required("Email is required"),
        address: Yup.string().required("Address is required"),
        race: Yup.string().required("Race is required"),
        height: Yup.number().required("height is required"),
        weight: Yup.number().required("Weight is required"),
        dob: Yup.date()
        .required('Date of birth is required')
        .max(maxDate, 'Date of birth must be no more than 5 years ago'),
        // medical_record: Yup.mixed().required('Miedical Record Picture is required'),
        driver_license: Yup.mixed().required("Driving License Picture is required"),
        vaccine: Yup.string().required("Select One of them"),
        // vaccine_name: Yup.string().required("Vaccine Name is required"),
        // vaccine_month: Yup.string().required("Vaccine Month is required"),
        // vaccine_year: Yup.string().required("Vaccine Year is required"),
        
        vaccine_name: Yup.string().when('vaccine',(value)=>{
           if(value[0]==="yes"){
            return Yup.string().required("Vaccine Name is required")
           }}),
           vaccine_month: Yup.string().when('vaccine',(value)=>{
           if(value[0]==="yes"){
           return  Yup.string().required("Vaccine Month is required")
           }}),
           vaccine_year: Yup.string().when('vaccine',(value)=>{
           if(value[0]==="yes"){
           return Yup.string().required("Vaccine Year is required")
           }})
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            address: "",
            race: "",
            height:"",
            weight: "",
            dob:"",
            medical_record: null,
            driver_license: null,
            vaccine: 'No',
            vaccine_name: '',
            vaccine_month: '',
            vaccine_year: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
           
            values={
                ...values,
                medical_record:medical,
                driver_license:license
            }
          
            setData({
                ...data,
                user:values
            })
            navigate("/covid2");
        },
    });

    const handleImageMedical = (e) => {
        const medical = e.target.files[0]
        console.log(medical, "user")
        setMedical(medical);
        // if (medical) {
        //     const reader = new FileReader();

        //     reader.onload = (event) => {
        //         const imageDataUrl = event.target.result;

        //         // Determine which image to update based on the imageNumber parameter

        //         setMedical(imageDataUrl);
        //     }
        //     reader.readAsDataURL(medical);
        // };

        // localStorage.setItem("medical", JSON.stringify(medical));
    }

    const handleImageLicense = (e) => {
        const license = e.target.files[0]
        // console.log(license, "user")
        setLicense(license);
        // if (license) {
        //     const reader = new FileReader();

        //     reader.onload = (event) => {
        //         const imageDataUrl = event.target.result;

        //         // Determine which image to update based on the imageNumber parameter
        //         setLicense(imageDataUrl);
        //     }
        //     reader.readAsDataURL(license);
        // };
        // localStorage.setItem("license", JSON.stringify(license));
    }


    return (
        <>
            <Layout>
                <section className="banner-section home-3">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                                <div className="offset-md-3 col-md-6">
                                    <div className="card bg-transparent rounded shadow-lg">
                                        <div className="container p-3">
                                            <form onSubmit={formik.handleSubmit} mult>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h5 className=" ">
                                                            Full Name *
                                                        </h5>
                                                        <input
                                                            type="string"
                                                            name="name"
                                                            className="form-control"
                                                            placeholder="Full Name"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.name}
                                                        />
                                                        <div className="text-danger">{formik.errors.name}</div>
                                                    </div>
                                                    <div className="col-md-6 mt-2">
                                                        <h5 className=" ">
                                                            Email *
                                                        </h5>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            className="form-control"
                                                            placeholder="Email"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.email}
                                                        />
                                                        <div className="text-danger">{formik.errors.email}</div>
                                                    </div>
                                                    <div className="col-md-6 mt-2">
                                                        <h5 className=" ">
                                                            Address *
                                                        </h5>
                                                        <input
                                                            type="string"
                                                            name="address"
                                                            className="form-control"
                                                            placeholder="Address"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.address}
                                                        />
                                                        <div className="text-danger">{formik.errors.address}</div>
                                                    </div>
                                                    <div className="col-md-6 mt-2">
                                                        <h5 className=" ">
                                                            Race *
                                                        </h5>
                                                        <select className="form-control"
                                                         name="race"

                                                         placeholder="Race"
                                                         onChange={formik.handleChange}
                                                         onBlur={formik.handleBlur}
                                                         value={formik.values.race}
                                                         
                                                        >
                                                         <option value="" disabled hidden>Choose Race    </option>
                                                         <option value="yellow">Yellow</option>
                                                        <option value="white">White</option>
                                                        <option value="black">Black</option>
                                                        </select>
                                                    
                                                        <div className="text-danger">{formik.errors.race}</div>
                                                    </div>
                                                    <div className="col-md-6 mt-2">
                                                        <h5 className=" ">
                                                            Height *
                                                        </h5>
                                                        <input
                                                            type="number"
                                                            name="height"
                                                            className="form-control"
                                                            placeholder="Height"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.height}
                                                        />
                                                        <div className="text-danger">{formik.errors.height}</div>
                                                    </div>
                                                    <div className="col-md-6 mt-2">
                                                        <h5 className=" ">
                                                            Weight *
                                                        </h5>
                                                        <input
                                                            type="number"
                                                            name="weight"
                                                            className="form-control"
                                                            placeholder="weight"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.weight}
                                                        />
                                                        <div className="text-danger">{formik.errors.weight}</div>
                                                    </div>
                                                    <div className="col-md-6 mt-2">
                                                        <h5 className=" ">
                                                            Date Of Birth *
                                                        </h5>
                                                        <input
                                                            type="Date"
                                                            name="dob"
                                                            className="form-control"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.dob}
                                                        />
                                                        <div className="text-danger">{formik.errors.dob}</div>
                                                    </div>
                                                    <div className="col-md-6 mt-2">
                                                        <h5 className=" ">
                                                            Medical records Picture *
                                                        </h5>
                                                        <input
                                                            type="file"
                                                            name="medical_record"
                                                            className="form-control"
                                                            onChange={(e)=>{handleImageMedical(e);formik.handleChange(e)}}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.medical_record}
                                                        />
                                                        <div className="text-danger">{formik.errors.medical_record}</div>
                                                    </div>
                                                    <div className="col-md-6 mt-2">
                                                        <h5 className=" ">
                                                            Driving License Picture *
                                                        </h5>
                                                        <input
                                                            type="file"
                                                            name="driver_license"
                                                            className="form-control"
                                                            onChange={(e)=>{handleImageLicense(e);formik.handleChange(e)}}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.driver_license}
                                                        />
                                                        <div className="text-danger">{formik.errors.driver_license}</div>
                                                    </div>
                                                    <div className="col-md-12 mt-2">
                                                        <h5 className=" ">
                                                            Are You Vaccinated ? *
                                                        </h5>
                                                        <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                            <input type="radio" class="btn-check" id="btncheck1" name="vaccine" autocomplete="off" onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value="yes" />
                                                            <label class="btn btn-outline-light" for="btncheck1">Yes</label>

                                                            <input type="radio" class="btn-check" id="btncheck2" name="vaccine" autocomplete="off" onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value="no" />
                                                            <label class="btn btn-outline-light" for="btncheck2">No</label>
                                                        </div>
                                                        <div className="text-danger">{formik.errors.vaccine}</div>
                                                    </div>
                                                    {formik.values.vaccine === "yes" ?
                                                        <>
                                                            <div className="col-md-6 mt-2">
                                                                <h5 className=" ">
                                                                    Vaccine Name
                                                                </h5>
                                                                <input
                                                                    type="string"
                                                                    name="vaccine_name"
                                                                    className="form-control"
                                                                    placeholder="Vaccine Name"
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.vaccine_name}
                                                                />
                                                                <div className="text-danger">{formik.errors.vaccine_name}</div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <h5 className=" ">
                                                                    Vaccine Month
                                                                </h5>
                                                                <input
                                                                    type="string"
                                                                    name="vaccine_month"
                                                                    placeholder="november"
                                                                    className="form-control"
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.vaccine_month}
                                                                />
                                                                <div className="text-danger">{formik.errors.vaccine_month}</div>
                                                            </div>
                                                            <div className="col-md-6 mt-2">
                                                                <h5 className=" ">
                                                                    Vaccine Year
                                                                </h5>
                                                                <input
                                                                    type="number"
                                                                    name="vaccine_year"
                                                                    placeholder="2023"
                                                                    className="form-control"
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.vaccine_year}
                                                                />
                                                                <div className="text-danger">{formik.errors.vaccine_year}</div>
                                                            </div>
                                                        </>
                                                        : null}
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <button
                                                            className="btn btn-warning   "
                                                            type="submit"
                                                        >
                                                            Next <i className="icofont-arrow-right text-primary" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default UserData