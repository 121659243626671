import React from "react";
import { Link } from "react-router-dom";

const MobileNav = () => {
  return (
    <>
      {/* Mobile Menu Start Here */}
      <div className="mobile-menu" id="home">
        <nav className="mobile-header">
          <div className="header-logo">
            <Link to="/">
              <img src="assets/images/logo/03.png" alt="logo" width={100} />
            </Link>
          </div>
          <div className="header-bar">
            <span />
            <span />
            <span />
          </div>
        </nav>
        <nav className="mobile-menu">
          <div className="mobile-menu-area">
            <div className="mobile-menu-area-inner">
              <ul className="lab-ul">
                <li>
                  <Link to="#home">Home</Link>
                </li>
              
               
              
                <li>
                  <Link to="#faq">Faq</Link>
                </li>
               
                <li>
                  <Link to="#contact">Contact</Link>
                </li>
                <li>
                <Link to="/covid-conidtion">
                      <span>Register Now</span>
                    </Link>
                    
                </li>
                <li>
                <Link to="https://admin.herbx1.com/admin/login" >
                      <span>Admin</span>
                    </Link>
                </li>
                <li>
                <Link to="https://admin.herbx1.com/subadmin/login" >
                      <span>Sub Admin</span>
                    </Link>
                </li>
                <li>
                <Link to="https://admin.herbx1.com/user/login" >
                      <span>User</span>
                    </Link>
                </li>
              
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {/* Mobile Menu Ending Here */}
    </>
  );
};

export default MobileNav;
