import React from "react";
import { Link } from "react-router-dom";

const HomeBanner = ({ isLoggedIn, hasFilledForm }) => {

  return (
    <>
      {/* Banner Section start here */}
      <section className="banner-section home-3">
        <div className="">
          <div className="banner-area">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-6 col-12 text-white position-relative welcome-text">
                  <h1 className="intor-gradinet-h1 ">Welcome To HerbX</h1>
                  <p className="text-justify text-18">
                  All natural herb remedy for Covid-19 or flu related symptoms! We are deeply appreciative of your interest and participation in this groundbreaking research. This trial represents a significant step forward in the development of innovative, plant-based treatments. As a participant, you will play a crucial role in advancing medical science and potentially improving health outcomes for many. We ensure a supportive, informative environment throughout the trial, prioritizing your safety and well-being at every step. Your contribution is invaluable, and we look forward to working together on this exciting venture towards medical advancement. Thank you for joining us in this important endeavor!
                  </p>
                  <p className="text-justify text-18">
                  Before proceeding, it’s essential to verify your eligibility for the Trial. Please make sure to review and meet the criteria listed on the following page. Your safety and well-being are our utmost priority. Confirming these details is a crucial step in your journey with us. Let’s dive in.”
                  </p>
                  <p>
                  <Link to="/covid-conidtion" className="btn btn-herb-blue text-18 btn-lg">
                      Register Now
                    </Link>
                  </p>
                </div>
                <div className="col-md-6 col-12 text-center">
                  <div className="flower-bg">
                    <img src="assets/images/banner/herbgreen-flower-1.png" className="img-fluid" alt="banner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Banner Section ending here */}
    </>
  );
};

export default HomeBanner;
