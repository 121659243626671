/* eslint-disable array-callback-return */
import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import Layout from "../../layout/Layout";
import { toast } from "react-toastify";
import { axiosInstance } from "../../config/AxiosInstance";
import { useNavigate } from "react-router-dom";

const Covid3 = () => {
  const [loader, setLoader] = useState(false);
  // Getting Previous Form Data
  let covid1 = localStorage.getItem("covidform1");
  covid1 = JSON.parse(covid1);
  let covid2 = localStorage.getItem("covidform2");
  covid2 = JSON.parse(covid2);

  const navigate = useNavigate("");
  const formSections = [
    {
      heading: "Participant Information",
      fields: [
        { label: "Participant ID", name: "participate_id", type: "text" },
        { label: "Participate Name", name: "participate_name", type: "text" },
        { label: "Email", name: "email", type: "text" },
        {
          label: "Gender",
          name: "gender",
          type: "text",
          inptype: "select",
          options: ["Male", "Female", "Other"],
        },
        {
          label: "Race",
          name: "race",
          type: "text",
          inptype: "select",
          options: ["White", "Black", "Asian", "Hispanic", "Other"],
        },
        { label: "Height", name: "height", type: "number" },
        { label: "Weight", name: "weight", type: "number" },
      ],
    },
    {
      heading: "Baseline Assessment",
      fields: [
        {
          label: "Date of Enrollment",
          name: "date_of_enrollment",
          type: "date",
        },
        {
          label: "COVID-19 Diagnosis Date",
          name: "diagnosis_date",
          type: "date",
        },
        {
          label: "Severity of COVID-19",
          name: "severity",
          type: "select",
          inptype: "select",

          options: ["Mild", "Moderate", "Severe", "Critical"],
        },
        {
          label: "Medical History",
          name: "medical_history",
          type: "text",
        },
        {
          label: "Concomitant Medication",
          name: "concomitant_medication",
          type: "text",
        },
      ],
    },
    {
      heading: "Treatment and Randomization",
      fields: [
        {
          label: "Treatment Group",
          name: "treatment_group",
          type: "select",
          inptype: "select",

          options: ["Herbal Drug ", "Placebo"],
        },
        {
          label: "Randomization ID",
          name: "randomization_id",
          type: "text",
        },
        {
          label: "Date of Randomization",
          name: "date_of_randomization",
          type: "date",
        },
      ],
    },
    {
      heading: "Outcome Measures",
      fields: [
        {
          label: "Date of Symptom Resolution",
          name: "date_of_symptom_resolution",
          type: "text",
        },
        {
          label: "Fever",
          name: "fever",
          type: "text",
          inptype: "select",
          options: ["Yes ", "No"],
        },
        {
          label: "Cough",
          name: "cough",
          type: "text",
          inptype: "select",
          options: ["Yes ", "No"],
        },
        {
          label: "Shortness of Breath",
          name: "shortness_of_breath",
          type: "text",
          inptype: "select",
          options: ["Yes ", "No"],
        },
        {
          label: "Sore Throat",
          name: "sore_throat",
          type: "text",
          inptype: "select",
          options: ["Yes ", "No"],
        },
        {
          label: "Fatigue",
          name: "fatigue",
          type: "text",
          inptype: "select",
          options: ["Yes ", "No"],
        },
        {
          label: "Loss of Taste or Smell",
          name: "loss_of_taste_or_smell",
          type: "text",
          inptype: "select",
          options: ["Yes ", "No"],
        },
        {
          label: "Other Symptoms",
          name: "other_symptoms",
          type: "text",
        },
      ],
    },
    {
      heading: "Viral Clearance",
      fields: [
        {
          label: "Date of First Negative PCR Test",
          name: "date_first_negative",
          type: "date",
        },
        {
          label: "Number of Days for Viral Clearance",
          name: "number_of_days_viral_clearance",
          type: "number",
        },
      ],
    },

    {
      heading: "Hospitalization",
      fields: [
        {
          label: "Hospitalization Duration",
          name: "hospitalization_duration",
          type: "text",
        },
        {
          label: "Intensive Care Unit (ICU) Admission",
          name: "intensive_care_unit",
          type: "text",
          inptype: "select",
          options: ["Yes", "No"],
        },
        {
          label: "Mechanical Ventilation Required",
          name: "mechanical_ventilation",
          type: "text",
          inptype: "select",
          options: ["Yes", "No"],
        },
      ],
    },
    {
      heading: "Adverse Events",
      fields: [
        {
          label: "Description of Adverse Events",
          name: "description_of_adverse_events",
          type: "text",
        },
        {
          label: "Severity",
          name: "severity_adverse",
          type: "text",
          inptype: "select",
          options: ["Mild", "Moderate", "Severe"],
        },
        {
          label: "Relatedness to Treatment",
          name: "relatedness_to_treatment",
          type: "text",
          inptype: "select",
          options: ["Unrelated", "Possible", "Probable", "Definite"],
        },
      ],
    },
    {
      heading: "Completion and Follow-up",
      fields: [
        {
          label: "Study Completion Date",
          name: "study_completion_date",
          type: "date",
        },
        { label: "Follow-up Period", name: "follow_up_period", type: "number" },
      ],
    },
  ];
  
  const formik = useFormik({
    initialValues: {
      participate_id: "",
      email:"",
      participate_name:"",
      gender: "",
      race: "",
      height: "",
      weight: "",
      date_of_enrollment: "",
      diagnosis_date: "",
      severity: "",
      medical_history: "",
      concomitant_medication: "",
      treatment_group: "",
      randomization_id: "",
      date_of_randomization: "",
      date_of_symptom_resolution: "",
      fever: false,
      cough: false,
      shortness_of_breath: false,
      sore_throat: false,
      fatigue: false,
      loss_of_taste_or_smell: false,
      other_symptoms: false,
      date_first_negative: "",
      number_of_days_viral_clearance: "",
      hospitalization_duration: "",
      intensive_care_unit: "",
      mechanical_ventilation: "",
      description_of_adverse_events: "",
      severity_adverse: "",
      relatedness_to_treatment: "",
      study_completion_date: "",
      follow_up_period: "",
    },
    validationSchema: Yup.object({
      participate_id: Yup.string().required("Participant ID is required"),
      participate_name:Yup.string().required("Participant Name is required"),
      email: Yup.string().required("email  is required"),
      height: Yup.number()
        .positive("Height must be a positive number")
        .required("Height is required"),
      weight: Yup.number()
        .positive("Weight must be a positive number")
        .required("Weight is required"),
      date_of_enrollment: Yup.date().required("Date of Enrollment is required"),
      diagnosis_date: Yup.date().required(
        "COVID-19 Diagnosis Date is required"
      ),
      severity: Yup.string().required("Severity Date is required"),
      medical_history: Yup.string().required(
        "medical history Date is required"
      ),
      concomitant_medication: Yup.string().required(
        "concomitant medication Date is required"
      ),
      treatment_group: Yup.string().required(
        "treatment group Date is required"
      ),
      randomization_id: Yup.string().required(
        "randomization id Date is required"
      ),
      date_of_randomization: Yup.date().required(
        "randomization id Date is required"
      ),
      date_of_symptom_resolution: Yup.date().required(
        "date of symptom resolution Date is required"
      ),
      fever: Yup.string().required(
        "fever of symptom resolution Date is required"
      ),
      cough: Yup.string().required(
        "cough of symptom resolution Date is required"
      ),
      shortness_of_breath: Yup.string().required(
        "shortness_of_breath of symptom resolution Date is required"
      ),
      sore_throat: Yup.string().required(
        "sore_throat of symptom resolution Date is required"
      ),
      fatigue: Yup.string().required(
        "fatigue of symptom resolution Date is required"
      ),
      loss_of_taste_or_smell: Yup.string().required(
        "loss_of_taste_or_smell of symptom resolution Date is required"
      ),
      other_symptoms: Yup.string().required(
        "other_symptoms of symptom resolution Date is required"
      ),
      date_first_negative: Yup.string().required(
        "date_first_negative of symptom resolution Date is required"
      ),
      number_of_days_viral_clearance: Yup.string().required(
        "number_of_days_viral_clearance of symptom resolution Date is required"
      ),
      hospitalization_duration: Yup.string().required(
        "hospitalization_duration of symptom resolution Date is required"
      ),
      intensive_care_unit: Yup.string().required(
        "intensive_care_unit of symptom resolution Date is required"
      ),
      mechanical_ventilation: Yup.string().required(
        "mechanical_ventilation of symptom resolution Date is required"
      ),
      description_of_adverse_events: Yup.string().required(
        "description_of_adverse_events of symptom resolution Date is required"
      ),
      severity_adverse: Yup.string().required(
        "severity_adverse of symptom resolution Date is required"
      ),
      relatedness_to_treatment: Yup.string().required(
        "relatedness_to_treatment of symptom resolution Date is required"
      ),
      study_completion_date: Yup.date().required(
        "study_completion_date of symptom resolution Date is required"
      ),
      follow_up_period: Yup.number().required(
        "follow_up_period of symptom resolution Date is required"
      ),
    }),
    onSubmit: (values) => {
      setLoader(true);
      const formdata = new FormData();
      //Covid Form 1
      formdata.append("age", covid1.age);
      covid1.viral_symptoms.map((symptom, i) => {
        formdata.append(`viral_symptoms[${i}]`, symptom.value);
      });
      formdata.append("legal_guardian", covid1.legal_guardian);
      //Covid Form 2
      formdata.append("date", covid2.date);
      formdata.append("participant_signature", covid2.participant_signature);
      formdata.append("principal_investigator", covid2.principal_investigator);
      formdata.append(
        "institutional_review_board",
        covid2.institutional_review_board
      );
      formdata.append("study_coordinator", covid2.study_coordinator);
      //Covid Form 3
      formSections.forEach((section) => {
        section.fields.forEach((field) => {
          formdata.append(field.name, values[field.name]);
        });
      });
      axiosInstance
        .post("participate/store", formdata)
        .then((response) => {
          if (response.data.status) {
            localStorage.removeItem("covidform1");
            localStorage.removeItem("covidform2");
            toast.success(response.data.message);
            navigate("/");
          } else {
            toast.error(response.data.message);
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    },
  });

  return (
    <>
    {loader&&<div className="loader"/>}
    <Layout>
      <section className="banner-section home-3">
        <div className="container">
          <div className="row">
            <div className=" col-md-12">
              <div className="card bg-transparent rounded shadow-lg">
                <div className="container p-3 ">
                  {" "}
                  <form onSubmit={formik.handleSubmit}>
                    {/* Participant Information Section */}
                    {formSections.map((e, i) => (
                      <>
                        <h2 className="text-center text-white">{e.heading}</h2>
                        <div className="row">
                          {e.fields?.map((e, i) => (
                            <div className={`col-md-4 mb-3`}>
                              <label
                                htmlFor={e.name}
                                className="form-label text-white"
                              >
                                {e.label}:
                              </label>
                              {e.inptype === "select" ? (
                                <select
                                  type={e.type}
                                  className={`form-control ${
                                    formik.touched[e.name] &&
                                    formik.errors[e.name]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  id={e.name}
                                  name={e.name}
                                  value={formik.values[e.name]}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                >
                                  <option selected hidden>
                                    Select Your {e.label}
                                  </option>
                                  {e.options.map((e) => (
                                    <option value={e}>{e}</option>
                                  ))}
                                </select>
                              ) : (
                                <input
                                  type={e.type}
                                  className={`form-control ${
                                    formik.touched[e.name] &&
                                    formik.errors[e.name]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  id={e.name}
                                  name={e.name}
                                  value={formik.values[e.name]}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              )}

                              {formik.touched[e.name] &&
                                formik.errors[e.name] && (
                                  <div className="invalid-feedback text-capitalize">
                                    {formik.errors[e.name].replace(/_/g, " ")}
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      </>
                    ))}
                    <div className="float-end">
                      <button
                        type="submit"
                        className="btn btn-warning mt-3 text-white "
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
    </>
  );
};

export default Covid3;
