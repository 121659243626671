import React,{useState} from 'react'
import { MainContext } from './MainContext'

 function MainProvider({children}) {
    const [data,setData]=useState({
        user:null,
        covidForm:null,
        testDate:"",
    })
  return (
   
    <MainContext.Provider value={{data,setData}}>
    {children}
</MainContext.Provider>
  )
}
export default MainProvider;