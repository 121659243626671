
import React from "react";


import Layout from "../../layout/Layout"

const NotEligible = () => {
   
    return (
        <>
            <Layout>
                <section className="banner-section home-3">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                                <div className="offset-md-3 col-md-6">
                                    <div className="card bg-transparent rounded shadow-lg">
                                        <div className="container p-3">
                                        <div className="row text-center p-5">
                                                <div className="col-md-12 ">
                                                        <h3 className="text-white text-xs">Not Eligible</h3>
                                                </div>
                                                <div className="col-md-12 d-flex gap-3 justify-content-center  align-items-center">
                                                  <h6 className="text-white">
                                                  Thank you for your interest, but unfortunately, we're unable to accommodate your registration at this time
                                                  </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default NotEligible