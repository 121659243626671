import { useFormik } from "formik";
import React, { useState } from "react";
import { axiosInstance } from "../../config/AxiosInstance";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
const Contact = () => {
  const [loader, setLoader] = useState(false);
  const loadercss = {
    display: "block",
    margin: "0 auto",
    borderColor: "white",
  };
  const formik = useFormik({
    initialValues:{
      name:"",
      email:"",
      address:"",
      company:"",
      message:"",

    },
    onSubmit:async(values)=>{
      setLoader(true)
      axiosInstance.post('contact/store',values).then((response)=>{
        console.log(response.data)
        toast.success(response.data.message);
        formik.handleReset()
        setLoader(false)
      }).catch((error)=>{
        console.log(error)
      })
    }
   

  })
  return (
    <>
      {/* Contact Section Start Here */}
      <section className="contact-us mt-5 mb-5" id="contactus">
        <div className="container">
          <div className="contact-wrap">
            <div className="row">
              <div
                className="col-lg-4 col-md-6 col-12 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <ul className="contact-area lab-ul">
                  <li className="contact-item">
                    <div className="contact-icon">
                      <img
                        src="assets/images/contact/icon/01.png"
                        alt="contact"
                      />
                    </div>
                    <div className="content">
                      <h6>Our Location</h6>
                      <p>
                        Florida, USA 
                      </p>
                    </div>
                  </li>
                  {/* <li className="contact-item">
                    <div className="contact-icon">
                      <img
                        src="assets/images/contact/icon/02.png"
                        alt="contact"
                      />
                    </div>
                    <div className="content">
                      <h6>Phone Number</h6>
                      <p>
                        01923-970212, 01776-502993 <br /> +2154897369
                      </p>
                    </div>
                  </li> */}
                  <li className="contact-item">
                    <div className="contact-icon">
                      <img
                        src="assets/images/contact/icon/03.png"
                        alt="contact"
                      />
                    </div>
                    <div className="content">
                      <h6>Email Address</h6>
                      <p>rpan@herb-green.com</p>
                    </div>
                  </li>
                  {/* <li className="contact-item">
                    <div className="contact-icon">
                      <img
                        src="assets/images/contact/icon/04.png"
                        alt="contact"
                      />
                    </div>
                    <div className="content">
                      <h6>Website Address:</h6>
                      <p>http://admincovid.com</p>
                    </div>
                  </li> */}
                </ul>
              </div>
              <div
                className="col-lg-8 col-md-6 col-xs-12 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                <form
                  className="contact-form"
                  onSubmit={formik.handleSubmit}
                
                >
                  <input
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    className="contact-input"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    className="contact-input"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    required
                  />
                  <input
                    type="text"
                    name="address"
                    placeholder="Your Address"
                    className="contact-input"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.addresss}
                    required

                  />
                  <input
                    type="text"
                    name="company"
                    placeholder="Your Company"
                    className="contact-input"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    required
                  />
                  <textarea
                    rows={5}
                    className="contact-input"
                    placeholder="Your Messages"
                    defaultValue={""}
                    name="message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    required
                  />
                  <button
                    type="submit"
                    
                    defaultValue="Send Message"
                    className="btn btn-lg btn-warning text-white "
                    disabled={loader ? true : false}

                  >
                   {loader ?
                       <ClipLoader
                       color={"white"}
                       loading={loader}
                       cssOverride={loadercss}
                       size={30}
                       aria-label="Loading Spinner"
                       data-testid="loader"
                     /> 
                     :
                     <span>   Submit <i className="icofont-arrow-right text-primary" /></span>
                      }
                    </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Section Ending Here */}
    </>
  );
};

export default Contact;
